@import '~reset-css/_reset';
@import '~foundation-sites/scss/foundation';
@import '~@angular/material/theming';

$breakpoint-classes: (small medium large xlarge);

@include foundation-flex-classes;
@include foundation-visibility-classes;
@include foundation-text-alignment;
@include foundation-xy-grid-classes(
    $base-grid: true,
    $block-grid: true,
    $collapse: true,
    $offset: true
);

@include mat-core();
